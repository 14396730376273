import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import { init as initCookiePermissions } from '@elements/cookie-permissions/bootstrap-5';
initCookiePermissions();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as navi from './navi';
navi.init();

import * as scrollNavi from './scroll-navi';
scrollNavi.init({
    className:'js-scroll-navi',
    enableAnimations:true,
    enableAnimationsMinWidth:false
});

import * as footer from './footer';
footer.init();