import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init();

import * as clouds from './scripts/clouds';
clouds.init();

import * as circleType from './scripts/circle-type';
circleType.init();

import * as teaserSlider from './scripts/teaser-slider';
teaserSlider.init();