const defaultSelectors = {};
const defaultOptions = {};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    options = {...defaultOptions, ...options};
    selectors = {...defaultSelectors, ...selectors};

    var div = document.querySelector('.js-clouds');
    const layers = [];

    window.addEventListener('mousemove', function(e){
        var x = (((100*e.clientX)/window.innerWidth)-50)*.25;
        var y = (((100*e.clientY)/window.innerHeight)-50)*.25;
        var t = 'rotateX('+x+'deg) rotateY('+y+'deg) scale(1.1)';
        //div.style.webkitTransform = div.style.MozTransform = div.style.oTransform = div.style.transform = t;
    });

    for(var img = 0; img < 6; img++){
        var cloud = document.createElement('img');
        cloud.setAttribute('src', '/static/img/cloud.png');
        var x = 256-(Math.random()*512);
        var y = 0;
        var z = 100-(Math.random()*200);
        var a = Math.random()*360;
        var s = 1;
        x *= .2;
        cloud.data = {
            x:x,
            y:y,
            z:z,
            a:a,
            s:s,
            speed:.1*Math.random()
        };
        var t = 'translateX('+x+'px) translateY('+y+'px) translateZ('+z+'px) rotateZ('+a+'deg) scale('+s+')';
        cloud.style.MozTransform = cloud.style.oTransform = cloud.style.transform = t;
        div.appendChild(cloud);
        layers.push(cloud);
    }
    update(layers);
}

function update(layers){
    for (var j = 0; j < layers.length; j++){
        var layer = layers[j];
        layer.data.a += .05;
        layer.data.s += layer.data.s >= 1.7 ? 0 : .0007;
        var t = 'translateX('+layer.data.x+'px) translateY('+layer.data.y+'px) translateZ('+layer.data.z+'px) rotateZ('+layer.data.a+'deg) scale('+layer.data.s+')';
        layer.style.webkitTransform = layer.style.MozTransform = layer.style.oTransform = layer.style.transform = t;
    }
    requestAnimationFrame(() => update(layers));
}