import { onFind } from '@elements/init-modules-in-scope';

const defaultSelectors = {};
const defaultOptions = {};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    options = {...defaultOptions, ...options};
    selectors = {...defaultSelectors, ...selectors};

    onFind('.js-menu__burger', function(burger){
        burger.addEventListener('click', function(e){
            const navi = burger.closest('.js-navi');
            navi.classList.toggle('is-open');
        });
    });

    onFind('.js-navi__toggle-submenu', function(toggleButton){
        toggleButton.addEventListener('click', function(e){
            const listItem = toggleButton.closest('li');
            listItem.classList.toggle('is-selected');
        });
    });
}