import CircleType from 'circletype';

const defaultSelectors = {};
const defaultOptions = {};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    options = {...defaultOptions, ...options};
    selectors = {...defaultSelectors, ...selectors};

    const object = document.querySelector('.js-circle-type');
    if (object){
        const circleTypo = new CircleType(object);
        circleTypo.radius(object.getAttribute('data-radius'));
        window.addEventListener('resize', () => resizeCircle(object, circleTypo));
        resizeCircle(object, circleTypo);
    }
}

function resizeCircle(object, circleTypo){
    if (window.innerWidth < 992){
        circleTypo.radius(object.getAttribute('data-radius-mobile'));
    } else {
        circleTypo.radius(object.getAttribute('data-radius'));
    }
}