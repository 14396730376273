import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { onFind } from '@elements/init-modules-in-scope';

SwiperCore.use([Navigation, Pagination]);

const defaultSelectors = {};
const defaultOptions = {};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    options = {...defaultOptions, ...options};
    selectors = {...defaultSelectors, ...selectors};

    onFind('.js-teaser-slider', function(baseElement){
        const swiper = new Swiper('.js-teaser-slider__container', {
            containerModifierClass:'js-teaser-slider__container--',
            wrapperClass:'js-teaser-slider__wrapper',
            slideClass:'js-teaser-slider__slide',
            slidesPerView:1.5,
            spaceBetween:12,
            watchSlidesProgress:true,
            watchSlidesVisibility:true,
            slideVisibleClass:'is-visible',
            navigation:{
                prevEl:'.js-teaser-slider__prev',
                nextEl:'.js-teaser-slider__next',
                disabledClass:'is-disabled',
                hiddenClass:'is-hidden',
                lockClass:'is-locked'
            },
            breakpoints:{
                576:{
                    slidesPerView:2,
                    spaceBetween:24
                },
                768:{
                    slidesPerView:3,
                    spaceBetween:24
                },
                992:{
                    slidesPerView:4,
                    spaceBetween:24
                }
            },
            pagination:{
                el:'.js-teaser-slider__pagination',
                type:'bullets',
                bulletActiveClass:'is-active',
                bulletClass:'bullet',
                modifierClass:'js-teaser-slider__pagination--',
                clickable:true,
                clickableClass:'is-clickable'
            }
        });
    });
}